<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-4">
            <div class="vx-col md:w-1/2 w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger">Excluir</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-1/3"><label class="vs-input--label">Nome</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3 flex">
                <vs-input class="w-full" v-model="data_local.modelo" :maxlength="255"/>   
            </div>     
        </div>     
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"

const URL_SERVLET = "/rest/ModeloVeiculo";
const NAME_ROUTE_SEARCH = "cadastro-modelo-veiculo-pesquisar";

export default {     
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return { modelo: null }
       }
    },
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),           
    }
  },    
  computed: {        
    isDeletable() {
       //return this.data_local != null && this.data_local.hasOwnProperty("id");
       return false; //Não pode excluir uma modelo
    },    
  },
  methods: {  
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('id', this.data_local.id);   
                
        axios.post(URL_SERVLET+'/Remover', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/login' });
            } else {                
                if (response.data.Resultado.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Resultado.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            
           if (this.data_local.hasOwnProperty("id")) {
                params.append('id', this.data_local.id);               
            }  

            params.append('modelo', this.data_local.modelo);      
            
            axios.post(URL_SERVLET+'/Gravar', params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
               if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/login' });
                } else {                    
                    if (response.data.Resultado.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Resultado.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
        if (!this.data_local.modelo) {
            this.errorsValidacao.push("Modelo obrigatório");
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>